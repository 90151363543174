import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/de/chapter_4/sub_3/slide1';

import WrapperMobile from 'src/slides/mobile/de/chapter_4/sub_3/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Polnische Armee gegen die Rote Armee | Schlacht bei Warschau" lang="de" description="Vergleich der Zahl der polnischen Armee und der sowjetischen Truppen zur Zeit der Schlacht bei Warschau" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Polnische Armee gegen die Rote Armee | Schlacht bei Warschau" lang="de" description="Vergleich der Zahl der polnischen Armee und der sowjetischen Truppen zur Zeit der Schlacht bei Warschau" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
